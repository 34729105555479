import { createGate } from "effector-react";
import { createEffect, sample } from "effector";

import { createToggleState } from "shared/lib/effector-states";
import { history } from "shared/config/histroy";
import { $newCustomer, newCustomerCreated } from "features/new-customer";
import {
  $selectedCustomer,
  selectedCustomerChanged,
} from "@manager-app/entities/customers";
import { $selectedBoat, selectedBoatChanged } from "entities/boats";
import {
  $newBoat,
  newBoatCreated,
} from "@manager-app/features/manage-boats/add";

import { $addons, $isCharter } from "@manager-app/entities/addons";

import {
  clientChanged,
  boatChanged,
  addonsChanged,
  isCharterChanged,
  newReservationCreated,
  ReservationWithClient,
  $selectedDock,
} from "@manager-app/features/manage-manager-reservation";

export { $selectedDock };

export const pageGate = createGate();
export const $isSelectBoatEnable = $selectedCustomer.map(Boolean);
export const $isSelectAddonsEnable = $selectedBoat.map(Boolean);

export const [$isNewCustomer, newCustomerToggled] = createToggleState();
export const [$isNewBoat, newBoatToggled] = createToggleState();

$isNewCustomer.on(newCustomerCreated, () => false);
$isNewBoat.on(newBoatCreated, () => false);

const reservationCreatedFx = createEffect(
  (reservation: ReservationWithClient) => {
    history.push(`/reservations-list/${reservation.id}/pay/`);
  }
);

sample({
  clock: newCustomerCreated,
  source: $newCustomer,
  target: selectedCustomerChanged,
});

sample({
  clock: newBoatCreated,
  source: $newBoat,
  target: selectedBoatChanged,
});

sample({
  source: $selectedCustomer,
  target: clientChanged,
});

sample({
  source: $selectedBoat,
  target: boatChanged,
});

sample({
  source: $addons,
  target: addonsChanged,
});

sample({
  source: $isCharter,
  target: isCharterChanged,
});

sample({
  clock: newReservationCreated,
  target: reservationCreatedFx,
});
