import { createEffect, createEvent, createStore } from "effector";

import { fromApi, getReservationSchedule } from "shared/api";
import { Boat, Client } from "shared/api/types";

import { createManagerReservationForm } from "./form";
import { postReservationWithClientCollection } from "../api";

const $client = createStore<Client | null>(null);
const $boat = createStore<Boat | null>(null);
const $addons = createStore<string[] | null>(null);
const $isCharter = createStore<boolean | null>(false);

export const clientChanged = createEvent();
export const boatChanged = createEvent();
export const addonsChanged = createEvent();
export const isCharterChanged = createEvent();

$client.on(clientChanged, (_, client) => client);
$boat.on(boatChanged, (_, boat) => boat);
$addons.on(addonsChanged, (_, addons) => addons);
$isCharter.on(isCharterChanged, (_, isCharter) => isCharter);

const SubmitFormFx = createEffect(postReservationWithClientCollection);

export const newReservationCreated = SubmitFormFx.doneData;
export const $formSubmitting = SubmitFormFx.pending;

export const {
  form: newReservationForm,
  $isLOADisabled,
  loaChangeModal,
  loaChangeModalActions,
  $isSubmitEnabled,
  dateTimeModel,
  $priceInfo,
  superOverrideModal,
  superOverrideModalActions,
  superOverrideNotConfirmed,
  $selectedDock,
  formGate,
} = createManagerReservationForm({
  onSubmit: SubmitFormFx,
  getAvailableItemsFx: createEffect(fromApi(getReservationSchedule)),
  $client,
  $boat,
  $addons,
  $isCharter,
});
